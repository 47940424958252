/**
 * 日期格式化
 */
export const formatDate = (mydate, pattern) => {
  const date = new Date(mydate)
  function format(m) {
    const n = m + 1
    if (n < 10) {
      return `0${n}`;
    }
    return n;
  }
  const year = date.getFullYear()
  const month = format(date.getMonth())
  const day = format(date.getDate() - 1)
  const hour = format(date.getHours() - 1)
  const minute = format(date.getMinutes() - 1)
  const second = format(date.getSeconds() - 1)
  switch (pattern) {
    case 'yyyy':
      return year
      break;
    case 'yyyy-MM-dd':
      return `${year}-${month}-${day}`
      break
    case 'yyyy.MM.dd':
      return `${year}.${month}.${day}`
      break
    case 'yyyy-MM-dd HH':
      return `${year}-${month}-${day} ${hour}`
      break
    case 'yyyy-MM-dd HH:mm':
      return `${year}-${month}-${day} ${hour}:${minute}`
      break
    case 'yyyy-MM-dd HH:mm:ss':
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
      break
    case 'yyyy/MM':
      return `${year}/${month}`
      break
    case 'MM月dd日':
      return `${month}月${day}日`
      break
    case 'HH:mm':
      return `${hour}:${minute}`
      break
    default:
      return 0
  }
}
