<template>
  <!-- 关于我们 -->
  <div class="about">
    <pc-header :active1="2"></pc-header>
    <div class="main">
      <div class="NeiBanner">
        <img
          style="width: 100%; height: 900px"
          :src="productBannerList.img_url"
          alt=""
        />
      </div>
      <div class="NeiNav">
        <div class="cmain">
          <ul>
            <!-- class="active"  -->
            <li @click="isShow(1)" :class="{ active: active == 1 }">
              <a href="#">
                <span class="span1">公司简介</span>
                <span class="span2">INTRODUCTION</span>
              </a>
            </li>
            <li @click="isShow(2)" :class="{ active: active == 2 }">
              <a href="#">
                <span class="span1">执业资质</span>
                <span class="span2">CERTIFICATE</span>
              </a>
            </li>
            <li @click="isShow(3)" :class="{ active: active == 3 }">
              <a href="#">
                <span class="span1">新闻动态</span>
                <span class="span2">NEWS</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="WfTopNav" v-if="val == 1">
        <div class="cmain">
          <div class="txt1">{{ companyDetail.title }}</div>
          <div class="txt2">ABOUT US</div>
          <div class="hen"></div>
        </div>
      </div>
      <div class="WfMainFigure" v-if="val == 1">
        <div class="cmain">
          <div class="WfMainImg">
            <img :src="companyDetail.img_url" alt="" />
          </div>
          <div
            class="WfMainTxt"
            :class="{ gundong: dg }"
            v-html="companyDetail.content"
          ></div>
          <div class="lookMore" @click="gundong">查看更多</div>
        </div>
      </div>
      <div class="WfMainFigure2" v-if="val == 1">
        <div class="cmain">
          <div class="WfImg2">
            <!-- <img src="../assets/images/nj2.jpg" alt="" /> -->
            <video :src="companyDetail.video_url" autoplay></video>
          </div>
          <!-- <div class="WfTxt2" v-html="companyDetail.content1"></div> -->
        </div>
      </div>

      <div class="cmain zz" v-if="val == 2">
        <div class="ACMain">
          <ul>
            <li v-for="(i1, p1) in certificateList" :key="p1">
              <a href="##">
                <dl>
                  <dt><img :src="i1.img_url" alt="" /></dt>
                  <dd>{{ i1.title }}</dd>
                </dl>
              </a>
            </li>
          </ul>
          <div class="more1" @click="lookMore">查看更多</div>
        </div>
      </div>

      <div class="cmain new-box" v-if="val == 3">
        <div class="ANMain">
          <ul>
            <li v-for="(item, index) in newList" :key="index">
              <a href="#">
                <dl>
                  <dt>
                    <img :src="item.img_url" alt="" />
                    <em class="mk"></em>
                  </dt>
                  <dd>
                    <span class="span1">【{{ item.time }}】</span>
                    <span class="span2">{{ item.title }}</span>
                    <span class="span3" @click="toDetail(item.id)">
                      <em>查看详情</em>
                      <i>+</i>
                    </span>
                  </dd>
                </dl>
              </a>
            </li>
          </ul>
        </div>
        <div class="fy">
          <div @click="prev" class="a">&lt;</div>
          <div>{{ newPage.size }}</div>
          <div @click="next" class="a">&gt;</div>
        </div>
      </div>

      <div class="cmain content" v-if="val == 4">
        <div v-html="newDetail.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "../utils/index";
import pcHeader from "@/components/pc-header";

export default {
  components: {
    pcHeader,
  },
  data() {
    return {
      val: "1",
      companyDetail: {},
      certificateList: [],
      newList: [],
      newid: "",
      newDetail: {},
      productBannerList: "",
      dg: false,
      active: 1,
      newPage: {
        size: 1,
        limit: 4,
      },
    };
  },
  methods: {
    gundong() {
      this.dg = true;
    },

    isShow(bln) {
      if (bln == 1) {
        this.val = bln;
        this.active = 1;
        this.$fetch({
          url: "/api/index/company",
        }).then((res) => {
          this.companyDetail = res.data.data;
        });
      }
      if (bln == 2) {
        this.val = bln;
        this.active = 2;
        this.$fetch({
          url: "/api/index/certificate",
          params: {
            size: 1,
            limit: 8,
          },
        }).then((res) => {
          this.certificateList = res.data.data;
        });
      }
      if (bln == 3) {
        this.val = bln;
        this.active = 3;
        this.$fetch({
          url: "/api/index/news",
          params: this.newPage,
        }).then((res) => {
          // let list = [];
          // res.data.data.forEach((item) => {
          //   item.time = formatDate(item.create_time * 1000, "yyyy-MM-dd");
          //   list.push(item);
          // });
          this.newList = res.data.data;
        });
      }
      if (bln == 4) {
      }
    },
    toDetail(id) {
      this.newid = id;
      this.$fetch({
        url: "/api/index/newsDetails",
        params: { id: this.newid },
      }).then((res) => {
        this.val = 4;
        this.newDetail = res.data.data;
      });
    },
    getProductBanner() {
      this.$fetch({
        url: "/api/index/productBanner",
        params: { type: 4 },
      }).then((res) => {
        this.productBannerList = res.data.data;
      });
    },

    // 一维数组转换为二维数组
    arrTrans(num, arr) {
      const iconsArr = []; // 声明数组
      arr.forEach((item, index) => {
        const page = Math.floor(index / num); // 计算该元素为第几个素组内
        if (!iconsArr[page]) {
          // 判断是否存在
          iconsArr[page] = [];
        }
        iconsArr[page].push(item);
      });
      return iconsArr;
    },

    // 上一页
    prev() {
      if (this.newPage.size == 1) {
        this.newPage.size = 1;
        this.$message.warning("当前已在第一页");
      } else {
        this.newPage.size--;
      }

      this.$fetch({
        url: "/api/index/news",
        params: this.newPage,
      }).then((res) => {
        let list = [];
        res.data.data.forEach((item) => {
          item.time = formatDate(item.create_time * 1000, "yyyy-MM-dd");
          list.push(item);
        });
        this.newList = list;
      });
    },
    // 下一页
    next() {
      this.newPage.size++;
      this.$fetch({
        url: "/api/index/news",
        params: this.newPage,
      }).then((res) => {
        let list = [];
        res.data.data.forEach((item) => {
          item.time = formatDate(item.create_time * 1000, "yyyy-MM-dd");
          list.push(item);
        });
        this.newList = list;
      });
    },

    lookMore() {
      this.$fetch({
        url: "/api/index/certificate",
        params: {
          size: 1,
          limit: 100,
        },
      }).then((res) => {
        this.certificateList = res.data.data;
      });
    },
  },
  created() {
    this.isShow(1);
    this.getProductBanner();
  },
};
</script>
<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 96px auto;
}
.zz {
  margin: 96px auto;
  .ACMain {
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .more1 {
      float: right;
      padding: 8px 20px;
      margin-top: 3px;
      border: 1px solid #f48512;
      font-size: 12px;
    }
  }
}
.WfMainFigure {
  height: 608px;
  .cmain {
    .WfMainImg {
      width: 500px;
      height: 608px;
      img {
        width: 500px;
        height: 608px;
      }
    }
    .WfMainTxt {
      width: 1000px;
      height: 580px;
    }
    .gundong {
      overflow: auto;
    }
    .lookMore {
      float: right;
      padding: 8px 20px;
      margin-top: 3px;
      border: 1px solid #f48512;
      font-size: 12px;
    }
  }
}
.new-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ANMain {
    width: 100%;
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .fy {
    display: flex;
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      background: #f48512;
      color: #fff;
      font-size: 20px;
      margin: 0 10px 96px;
      cursor: pointer;
      font-weight: bold;
    }
    .a {
      background: #ccc;
    }
  }
}
</style>
